import { Container, Box } from "@mui/material"
import React from "react"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"

const Policy = () => {
  // useEffect(() => {
  //   window.location.replace('https://youtu.be/r_i-owe88EA')
  // }, [])
  return (
    <Layout>
      <Seo title="Policy" />
      <Box mb={4} className="center-child" flexDirection="column" paddingX={3}>
        {/* <h2
          style={{ marginBottom: 30, fontWeight: 'bold' }}
          className="text-center"
        >
          POLICY
        </h2> */}
        <Container maxWidth="md" className="policy">
          <h5>RETURNS & EXCHANGES</h5>
          <p>
            All sales final - no returns or exchanges. Due to the exclusive
            nature and high demand for the merchandise, all sales are final.
            Please check that your chosen product sizing, shipping address, and
            billing address that you are submitting is correct as it cannot be
            modified or changed. As orders cannot be modified, they can only be
            canceled if there are critical errors in your order. Stock is not
            available for size corrections and/or exchanges. If your item
            arrived damaged, please contact prdsaigonservice@gmail.com within 30
            days.
          </p>
          <h5>INTERNATIONAL SHIPPING / CUSTOMS</h5>
          <p>
            You are responsible for all duties and taxes on your order. If you
            refuse a shipment from Paradise Saigon, you are responsible for the
            original shipping charges, any import fees, duties and/or taxes that
            are incurred on the package, and the cost of returning the package
            to Paradise Saigon. This amount will be deducted from your
            merchandise store credit. In the instance that the return fee
            exceeds the amount of the merchandise plus shipping costs, the
            package will be abandoned and you will not be issued a credit.
            Depending on your country you will have the option to pre-pay your
            duties and taxes at checkout. This means you will not have to pay
            these fees when your order arrives, and we guarantee that you will
            not be required to pay additional costs upon delivery. Duties and
            taxes are calculated based on the items ordered, your shipment
            destination, and the value of your purchase, and are added to your
            order total in the shopping cart. Duties and taxes are
            non-refundable. These duties & taxes are beyond our control as they
            are set by the Customs Authority of the destination country and
            depend on a number of factors, such as the country of origin of the
            purchased product, local VAT rates, & Local import taxes. Deliveries
            to some countries also have the option at checkout to not pay for
            the duties, taxes, and fees upfront. Instead, you agree to pay all
            applicable fees upon delivery. We cannot guarantee the cost of the
            fees upon delivery, as the fees are calculated by your local customs
            authority. It will be your responsibility to pay these charges if
            and when asked to by your local authorities.
          </p>
          <h5>INVALID OR INSUFFICIENT ADDRESS</h5>
          <p>
            If your items are returned because of an invalid or insufficient
            address we will have to charge you to reship your package. If you do
            not want your package to be reshipped, you will be issued a store
            credit for the order total, and the shipping amount will be deducted
            from your store credit amount.
          </p>
          <h5>COOKIES</h5>
          <p>
            By using our website, you (the visitor) agree to allow third parties
            to process your IP address, in order to determine your location for
            the purpose of currency conversion. You also agree to have that
            currency stored in a session cookie in your browser (a temporary
            cookie that gets automatically removed when you close your browser).
            We do this in order for the selected currency to remain selected and
            consistent when browsing our website so that the prices can convert
            to your (the visitor) local currency.
          </p>
        </Container>
      </Box>
    </Layout>
  )
}

export default Policy
